@media only screen and (max-width: 600px) {


  .inputLoyalty {
    width: 250px !important;
  }

  .divBeneficiario {
    display: inline-table !important;
    text-align: left;
    margin-top: 15px;
  }

  .rigth {
    position: absolute !important;
    left: -95px !important;
    display: inline !important;
    top: 700px;
  }

  .btnCad {
    margin-top: 20px !important;
  }


  .btnCad2 {
    position: relative;
    top: -30px !important;
  }

  .btnResgate {
    position: relative !important;
    margin-bottom: 60px;
  }

  .rigth {
    height: 200px;
  }

  .inputCPFStepOne {
    margin-top: 5px !important;
  }

}

.divParent {
  justify-content: center;
  display: flexbox;
}

.containerSteps {
  margin: 0 auto 5rem;
  width: 60%;
}

.divMiddle {
  text-align: center;
}

.divBeneficiario {
  display: flex;
  margin: 1rem 30% 1rem;
  align-items: center;
}

.left {
  float: left;
  display: grid;
  text-align: left;
  width: 40%;
}

.rigth {
  text-align: left;
  margin-left: auto;
}

.vertical-center {
  display: flex;
  position: relative;
  left: 100px !important;
}

.labelsDiv {
  margin-left: 0.625rem;
}

.labelPontos {
  margin-top: 1rem;
  font-size: 16pt;
}

.labelValor {
  margin: 0;
  width: fit-content;
}

.labelPontosBeneficiario {
  margin: 0;
  width: fit-content;
}


.inputLoyalty {
  width: 400px;
  margin-bottom: 20px !important;
  font-size: 16pt !important;
}

.inputPoint {
  width: 130px !important;
  margin-bottom: 20px !important;
  border: 1px solid #d9d9d9;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.ant-input::placeholder {
  color: rgba(0, 0, 0, 0.65) !important;
}

input:hover {
  border-color: #6fb7fa;
}

input:disabled {
  color: #b7b7b7;
  background-color: #f5f5f5;
}

.inputCPFStepOne {
  margin-top: 50px;
  margin-bottom: -15px !important;
  width: 250px !important;
  font-size: 16pt !important;
}


.inputCPF {
  width: 250px !important;
  font-size: 16pt !important;
}

.inputCad {
  font-size: 16pt !important;
  width: 250px !important;
}

.btnCad {
  margin-top: 60px;
  margin-right: 10px;
}

/* Duplicado por conta da responsividade, não mexa! */
.btnCad2 {
  margin-top: 60px;
  margin-right: 10px;
}


.btnResgate {
  top: 50px;
  left: 20px;
  background-color: #99139e !important;
}

.steps {
  margin-bottom: -3px !important;
}

.divResgatePontos {
  margin-top: 15px !important;
}

.txtCelularLocalizacao {
  margin-top: 15px !important;
}