label {
    display: flex;
    align-items: center;
    margin: 1rem 0 0;
    font-weight: 500;
}

input,
.ativoSelect {
    color: rgba(0, 0, 0, .85);
}

input::placeholder {
    font-weight: 400;
    opacity: 70%;
}

.inputCrud {
    width: 100%;
    height: 32px;
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}

.inputCrud:hover {
    border-color: #c175c4;
    transition: 300ms;
}

.ativoSelect {
    width: 120px;
}

.modalButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.875rem;
}