@media only screen and (max-width: 600px) {

    .cards {
        display: inline-block !important;
    }

    .cardPontos {
        margin-top: 10px;
        max-width: 250px !important;
    }

    .cardBeneficiario {
        margin-top: 10px;
        max-width: 250px !important;
        left: -0px !important;
    }


    .labelNomeBeneficiario {
        font-size: 24px !important;
    }

    .tableSaldos {
        margin-top: 80px !important;
    }


}


.containerSaldos {
    height: 100%;
    padding-bottom: 1.25rem;
    justify-content: space-between;
    display: flex;
}

.containerSaldos label {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 0;
}

.containerSaldos button {
    margin-left: 10px;
}

.containerSaldos .containerOptions {
    display: flex;
    align-items: center;
}

.datePicker {
    width: 25rem;
}

.configIcon {
    font-size: 1.25rem;
    margin-left: 0.625rem;
}

.configIcon:hover {
    cursor: pointer;
    color: #813688;
    transition: color 500ms;
}

.modalConfig .title {
    font-weight: bold;
}

.modalConfig .containerOptions:first-of-type {
    margin-bottom: 1.25rem;
}

.modalConfig .checkbox {
    display: block;
    margin-left: 0.5rem;
}

.inputCPF {
    margin-bottom: 0 !important;
}

.tableSaldos {
    margin-top: 20px;
}


.labelNomeBeneficiario {
    display: flex;
    flex-direction: row;
    justify-content: left;
    font-size: 40px;
    font-weight: 700;
    margin-top: 20px;
}

.cards {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 20px 40px 20px 40px;
    height: 10rem;
}

.cardPontos {
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    color: white;
}

.cardPontos label {
    position: relative;
    top: -10px;
}

.cardBeneficiario {
    /* background-color: rgb(45, 189, 74); */

    border: 1px solid;
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    position: relative;
    left: 10px;
    width: 100%;
}

.cardBeneficiario label {
    position: relative;
    top: -20px;
}