.lblHeaderConfig {
    position: fixed !important;
    left: 240px !important;
    font-size: 18px;
}

.div-form-profile {
    position: relative;
    width: 800px;
    margin-top: 50px;
}

.div-form-profile label {
    position: relative;
    left: -50px;
    width: 140px !important;
}

.div-form-profile input {
    position: relative;
    left: -40px;
    width: 546px;
}

.uploadComponent {
    display: none !important;
}