@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');


.bodyLogin {
    background-image: url('../../assets/login-background.jpg');
    background-size: cover;
    background-position: center center;
    position: absolute;
    width: 100% !important;
    height: 100%;

}


@media only screen and (max-width: 600px) {
    .centerLoginAdmin {
        width: 80% !important;
        height: 60% !important;
        position: relative;
    }

    .login-form-admin .ant-input {
        width: 250px;
        margin-left: 20px;
    }

    .login-form-admin .ant-input-prefix {
        margin-left: 20px;
    }
    .login-form-admin .ant-input-suffix {
        margin-right: 20px;
    }

    .login-form-button-admin {
        width: 100px !important;
    }

    .EsqueciSenhaAdmin {
        position: relative !important;
        margin-right: 10px;
    }
    
    

}

.centerLoginAdmin {
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: 30%;
    height: 70%;
    text-align: center;

    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.83);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.83);
    box-shadow: rgba(0,0,0,0.83) 0px 30px 60px -12px inset, rgba(0,0,0,0.83) 0px 18px 36px -18px inset;
}



.titleLoginH1Admin {
    font-size: 48px;
    font-family: 'Poppins-Bold';
    color: white;
    position: relative;
    top: 10px;
}

.titleLoginH6Admin {
    font-size: 18px;
    font-family: 'Poppins-Bold';
    position: relative;
    top: -20px;
    color: white;
}

.login-form-admin {
    max-width: 300px;
    position: relative;
    top: 30%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.login-form-forgot-admin {
    float: right;
}

.login-form-button-admin {
    width: 100%;
}

.EsqueciSenhaAdmin {
    float: right;
    color: white !important;
    text-decoration: underline !important;
}

.bodyLogin .ant-form-explain {
    color: yellow !important;
}