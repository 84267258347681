.textError {
    width: 400px;
    background-color: #f0f7fb;
    background-size: 30px;
    background-position: 10px 15px;
    background-repeat: no-repeat;
    border: solid 1px #3498db;
    border-radius: 6px;
    line-height: 18px;
    padding-left: 50px !important;
    padding-right: 1px !important;
    margin-left: 230px;
    position: relative;
    top: -30px;
}

.alphabet {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.alphabet button {
    margin: 2px;
}

.ant-table-footer {
    display: flex;
    justify-content: center;
    background-color: white;
    padding: 0;
}

.ant-table-footer::before {
    background-color: white;
}

.filterContainer {
    margin-bottom: 1.25rem;
}

.noteMessage {
    display: flex;
    align-items: center;

    margin: 0 0 0.625rem 0.625rem;
    padding: 0.625rem;
    border-radius: 0.625rem;

    background-color: #813688;
    color: white;

    font-size: 1rem;
}

.searchContainer {
    margin-bottom: 0.625rem;
    align-items: center;
}

.checkboxContainer {
    margin-left: 0.625rem;
}