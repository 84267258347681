.inputText {
  margin-bottom: 10px;
  border: 1px solid #d9d9d9;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.cpf .phone {
  margin-bottom: 20px !important;
  width: 250px !important;
  font-size: 16pt !important;
}

.confirmation {
  width: 12rem !important;
}